<template>
  <div
    id="app" 
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>
    <scroll-to-top
      v-if="enableScrollToTop"
      :class="isLandingPage ? 'btn-scroll-to-top--landing-page' : ''"
    />
    <aom-session-timeout v-if="isLoggedIn" />
    <aom-environment v-if="isLoggedIn" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue';
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig';
// import { provideToast } from 'vue-toastification/composition';
import { watch } from '@vue/composition-api';
import useAppConfig from '@core/app-config/useAppConfig';
import { useWindowSize, useCssVar } from '@vueuse/core';
import store from '@/store';
import { mapGetters, mapActions } from 'vuex';
import initLog from '@/logging/aom-app-log';
import { getProgramData, isUserLoggedIn, getUserData } from '@/auth/utils';
import { userRoles } from '@/models';
import { PRIMARY_COLOR, SECONDARY_COLOR, setRootElementCssVars } from "@/libs/utils";
import AomSessionTimeout from '@aom-core/AomSessionTimeout.vue';
import AomEnvironment from '@aom-core/AomEnvironment.vue';
const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue');
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue');
const LayoutParticipant = () => import('@/layouts/participant/LayoutParticipant.vue');
const { NODE_ENV } = process.env;

export default {
  components: {

    LayoutVertical,
    LayoutFull,
    LayoutParticipant,
    ScrollToTop,
    // Session Timeout
    AomSessionTimeout,
    AomEnvironment
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    ...mapGetters('profile', ['isLoggedIn', 'loggedUser']),
    ...mapGetters('programs',['programById', 'defaultProgramId']),

    appCurrentProgram() {
      return this.$store.getters['app/currentProgram'];
    },
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full';
      if (this.$route.meta.layout === 'participant') return 'layout-participant';
      if (this.$route.meta.layout === 'participant-then-vertical') {
        if (!this.isLoggedIn) {
          return 'layout-participant';
        }
        return 'layout-vertical';
      }
      if (this.$route.meta.layout === 'full-then-vertical') {
        if (!this.isLoggedIn) {
          return 'layout-full';
        }
        return 'layout-vertical';
      }

      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
    isLandingPage() {
      return this.$route.name === "landing-page" ? true : false;
    }
  },
  watch:{
    $route: {
      handler (n) {
        if(n?.name) {
          // In case: Set portal for preview landing page of champion portal
          if (n.query?.portal) {
            store.commit("app/SET_PORTAL", n.query?.portal);
          }

          if (n.name.indexOf('admin-') === 0) {
            store.commit('app/SET_PORTAL', 'admin');
            this.changeProgramColors();
          } else if (n.name.indexOf('champion-') === 0) {
            store.commit('app/SET_PORTAL', 'champion');
          } else if (n.name.indexOf('participant-') === 0) {
            store.commit('app/SET_PORTAL', 'participant');
          } else {
            store.commit('app/SET_PORTAL', 'admin');
            this.changeProgramColors();
          }
        }
      },
      deep: true,
      immediate: true
    },
    '$route.params': {
      handler (n) {
        if(n && n.id) {
          this.changeProgramColors(n.id);
        }
      },
      deep: true,
      immediate: true
    },
    defaultProgramId: {
      handler (n) {
        this.changeProgramColors(n);
      },
      deep: true,
      immediate: true
    }
  },
  beforeCreate() {
    // set program colors to document root
    setRootElementCssVars(PRIMARY_COLOR, SECONDARY_COLOR);

    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark'];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2));
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr');
  },
  async created() {
    this.mapEnvState();
    // A Set user Data if page is reloaded
    let user = null;
    if (isUserLoggedIn()) {
      user = getUserData();
      await this.FETCH_AUTHED_USER();
      const userData = this.loggedUser;
      if (userData.role === userRoles.MENTOR || userData.role === userRoles.MENTEE || userData.role === userRoles.TRAINEE) {
        // Port programData from localStorage to store
        const programData = getProgramData();
        if (programData) {
          this.$store.commit('participants/SET_CURRENT_PROGRAM', programData);
        }
      }
      if(userData.role === userRoles.ADMIN) {
        store.dispatch('app/RELOAD_APP_DATA');
      }
      console.debug(`Environment: ${NODE_ENV}`);
    }
    if(process.env.VUE_APP_DATADOG_LOG_TOKEN  && process.env.VUE_APP_DATADOG_LOG_SITE){ 
      initLog(NODE_ENV, user);
    };
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();
    const { enableScrollToTop } = $themeConfig.layout;

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout');

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    // provideToast({
    //   hideProgressBar: true,
    //   closeOnClick: false,
    //   closeButton: false,
    //   icon: false,
    //   timeout: 3000,
    //   transition: 'Vue-Toastification__fade',
    // });

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val);
    });

    return {
      skinClasses,
      enableScrollToTop,
    };
  },
  methods: {
    mapEnvState() {
      if(!process.env.VUE_APP_DATADOG_LOG_TOKEN  || !process.env.VUE_APP_DATADOG_LOG_SITE){
        console.log('The DataDog service is disabled due to environment keys missing.');
      }
      if(!process.env.VUE_APP_PUSHER_APP_KEY || !process.env.VUE_APP_PUSHER_APP_CLUSTER){
        console.log('The Pusher service is disabled due to environment keys missing.');
      }
      if(!process.env.VUE_APP_TINY_MCE_API_KEY){
        console.log('The Tiny_MCE service is disabled due to environment keys missing.');
      }
    },
    ...mapActions('profile', ['FETCH_AUTHED_USER']),
    changeProgramColors(n) {
      if(this.$route.meta.resource === 'ChampionRoutes') {
        const program = this.programById(n);
        if(program && program.length > 0) {
          setRootElementCssVars(program[0].theme_primary_color, program[0].theme_secondary_color);
        }
      } else {
        if (this.appCurrentProgram) {
          setRootElementCssVars(this.appCurrentProgram.theme_primary_color || PRIMARY_COLOR, this.appCurrentProgram.theme_secondary_color || SECONDARY_COLOR);
        } else {
          setRootElementCssVars(PRIMARY_COLOR, SECONDARY_COLOR);
        }
      }
    }
  }
};
</script>
<style scoped>
@media only screen and (min-width: 1024px) {
  .btn-scroll-to-top--landing-page {
    bottom: 150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .btn-scroll-to-top--landing-page {
    bottom: 100px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .btn-scroll-to-top--landing-page {
    bottom: 110px;
  }
}
</style>

<style lang="scss">
@import '@/assets/scss/vue/libs/vue-select.scss';
.badge-outline-primary {
  color: #6e6b7b !important;
  background: none;
  border: 1px solid var(--primary)
}
.badge-outline-danger {
  color: var(--danger) !important;
  background: none;
  border: 1px solid var(--danger)
}
</style>
