import { 
  advancedSeachModel,
  entitys, 
  commonOperators, 
  usersRolesFilterFields, 
  applicationsFilterFields, 
  aggregate,
  aggregateTypes, 
  aggregateTypesDisplay,
  filters,
  trainingFilterFields
} from './advancedSearch';
import { partnerPrefercingRules, partnerPreferEntitys } from './partnerPrefercingRules';
import { 
  userRoles, 
  formStatus,
  userStatus,
  operatorCommonDisplay,
  numericalStringDisplay,
  trainingStatus
} from "@models";

export const trainingTemplates = [
  {
    name: 'Participants who have started a training module', 
    entity: {id: entitys.TRAININGS, name: 'Trainings'},
    filters: [
      {
        ...advancedSeachModel[entitys.USER_ROLES],
        fieldDisplay: advancedSeachModel[entitys.USER_ROLES].field,
        operatorDisplay: advancedSeachModel[entitys.USER_ROLES].op,
        valueDisplay:  advancedSeachModel[entitys.USER_ROLES].values,
        type: entitys.USER_ROLES,
        expressions: [
          { 
            field: usersRolesFilterFields.STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: userStatus.ACTIVE
          }
        ],
        type: entitys.USER_ROLES
      },
      {
        ...advancedSeachModel[entitys.TRAININGS],
        fieldDisplay: advancedSeachModel[entitys.TRAININGS].field,
        operatorDisplay: advancedSeachModel[entitys.TRAININGS].op,
        valueDisplay:  advancedSeachModel[entitys.TRAININGS].values,
        type: entitys.TRAININGS,
        expressions: [
          { 
            field: trainingFilterFields.TRAINING_STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: trainingStatus.STARTED
          },
          { 
            field: trainingFilterFields.TRAINING_ID,
            op: commonOperators.OP_EQUAL_TO
          }
        ],
        type: entitys.TRAININGS
      }
    ]
  },
  {
    name: 'Participants who have completed a training module', 
    entity: {id: entitys.TRAININGS, name: 'Trainings'},
    filters: [
      {
        ...advancedSeachModel[entitys.USER_ROLES],
        fieldDisplay: advancedSeachModel[entitys.USER_ROLES].field,
        operatorDisplay: advancedSeachModel[entitys.USER_ROLES].op,
        valueDisplay:  advancedSeachModel[entitys.USER_ROLES].values,
        type: entitys.USER_ROLES,
        expressions: [
          { 
            field: usersRolesFilterFields.STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: userStatus.ACTIVE
          }
        ],
        type: entitys.USER_ROLES
      },
      {
        ...advancedSeachModel[entitys.TRAININGS],
        fieldDisplay: advancedSeachModel[entitys.TRAININGS].field,
        operatorDisplay: advancedSeachModel[entitys.TRAININGS].op,
        valueDisplay:  advancedSeachModel[entitys.TRAININGS].values,
        type: entitys.TRAININGS,
        expressions: [
          { 
            field: trainingFilterFields.TRAINING_STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: trainingStatus.COMPLETE
          },
          { 
            field: trainingFilterFields.TRAINING_ID,
            op: commonOperators.OP_EQUAL_TO
          }
        ],
        type: entitys.TRAININGS
      }
    ]
  },
  {
    name: 'Participants who have not started a training module', 
    entity: {id: entitys.TRAININGS, name: 'Trainings'},
    filters: [
      {
        ...advancedSeachModel[entitys.USER_ROLES],
        fieldDisplay: advancedSeachModel[entitys.USER_ROLES].field,
        operatorDisplay: advancedSeachModel[entitys.USER_ROLES].op,
        valueDisplay:  advancedSeachModel[entitys.USER_ROLES].values,
        type: entitys.USER_ROLES,
        expressions: [
          { 
            field: usersRolesFilterFields.STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: userStatus.ACTIVE
          }
        ],
        type: entitys.USER_ROLES
      },
      {
        ...advancedSeachModel[entitys.TRAININGS],
        fieldDisplay: advancedSeachModel[entitys.TRAININGS].field,
        operatorDisplay: advancedSeachModel[entitys.TRAININGS].op,
        valueDisplay:  advancedSeachModel[entitys.TRAININGS].values,
        type: entitys.TRAININGS,
        expressions: [
          { 
            field: trainingFilterFields.TRAINING_STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: trainingStatus.NOT_STARTED
          },
          { 
            field: trainingFilterFields.TRAINING_ID,
            op: commonOperators.OP_EQUAL_TO
          }
        ],
        type: entitys.TRAININGS,
        // aggregate: {
        //   ...aggregate,
        //   typeDisplay: [{ id: aggregateTypes.COUNT, name: 'Count'}],
        //   type: aggregateTypesDisplay[1].id,
        //   op: numericalStringDisplay[0].id,
        //   value: 0
        // }
      }
    ]
  },
];
