import { $themeBreakpoints } from '@themeConfig';
import programsService from "@/services/programsService";
import actionableEventsService from "@/services/actionableEventsService";
import { setRootElementCssVars, localeDateStringFromIsoDateTimeWithoutTimezone } from "@/libs/utils";
import localesService from "@services/localesService.js";

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    currentProgram: null,
    portal: JSON.parse(localStorage.getItem('PORTAL')),
    customPageTitle: '',
    actionableEvents: [],
    keyDates: [],
    defaultLanguages: [],
    programLanguages: []
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state;
      if (windowWidth >= $themeBreakpoints.xl) return 'xl';
      if (windowWidth >= $themeBreakpoints.lg) return 'lg';
      if (windowWidth >= $themeBreakpoints.md) return 'md';
      if (windowWidth >= $themeBreakpoints.sm) return 'sm';
      return 'xs';
    },
    currentProgram: state => state.currentProgram,

    isAdminPortal: state => state.portal === 'admin',
    isChampionPortal: state => state.portal === 'champion',
    isParticipantPortal: state => state.portal === 'participant',

    customPageTitle: state => state.customPageTitle,

    appActionableEvents: state => state.actionableEvents.map(item => ({id: item.id, name: item.name})),
    appKeyDates: state => state.keyDates.map(item => {
      const keyDate = item.key_dates && item.key_dates.length > 0
        ? localeDateStringFromIsoDateTimeWithoutTimezone(item.key_dates[0].date)
        : "";
      return {
        id: item.id,
        name: item.key_dates && item.key_dates.length > 0 ? item.key_dates[0].title : item.name,
        date: keyDate,
        rawDate: item.key_dates && item.key_dates.length > 0 ? item.key_dates[0].date : '',
        isKeyDate: true,
        program_id: item.program_id
      };
    }),

    languages: state => {
      let languages = [];
      if (state.programLanguages.length > 0) {
        languages = state.programLanguages;
      } else {
        languages = state.defaultLanguages;
      }

      return languages.map(lang => ({
        id: lang.id,
        name: lang.name,
        abbreviation: lang.abbreviation,
      }));
    },

    locales: (state, getters) => {
      return getters.languages.map(l => l.id);
    }
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val;
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay;
    },
    SET_CURRENT_PROGRAM(state, val) {
      state.currentProgram = val;
    },
    SET_PORTAL(state, value) {
      localStorage.setItem('PORTAL', JSON.stringify(value));
      state.portal = value;
    },
    SET_CUSTOM_PAGE_TITLE(state, value) {
      state.customPageTitle = value;
    },
    SET_ACTIONABLE_EVENTS(state, value) {
      state.actionableEvents = value;
    },
    SET_KEY_DATES(state, value) {
      state.keyDates = value;
    },
    SET_DEFAULT_LANGUAGES(state, value) {
      state.defaultLanguages = value;
    },
    SET_PROGRAM_LANGUAGES(state, value) {
      state.programLanguages = value;
    }
  },
  actions: {
    async RELOAD_APP_DATA({ dispatch, state, commit, rootGetters }) {
      // Dont need to load default languages again
      if (state.defaultLanguages.length === 0) {
        await dispatch('FETCH_APP_LANGUAGES');
      }
    },

    async FETCH_CURRENT_PROGRAM({ state, commit, rootGetters }, params) {
      if(!state?.currentProgram?.id || state?.currentProgram?.client?.path !== params.subdomain || state?.currentProgram?.path !== params.programPath) {
        const result = await programsService.getProgramByClientSubdomainAndProgramPath(params.subdomain, params.programPath);
        if (result && result.data) {
          commit('SET_CURRENT_PROGRAM', result.data);
          setRootElementCssVars(result.data.theme_primary_color, result.data.theme_secondary_color);
        }
      }
    },

    async FETCH_ACTIONABLE_EVENTS({ state, commit, rootGetters }, programId) {
      const result = await actionableEventsService.getActionableEvents();

      if (result && result.data) {
        const items = result.data.items;
        commit('SET_ACTIONABLE_EVENTS', items.filter(item => !item.is_key_date));
        commit('SET_KEY_DATES', items.filter(item => item.is_key_date));
      }
    },

    async FETCH_PROGRAM_ACTIONABLE_EVENTS({ state, commit, rootGetters }, programId) {
      const result = await actionableEventsService.getProgramActionableEvents(programId);

      if (result && result.data) {
        const items = result.data.items;
        commit('SET_ACTIONABLE_EVENTS', items.filter(item => !item.is_key_date));
        commit('SET_KEY_DATES', items.filter(item => item.is_key_date));
      }
    },
    async FETCH_APP_LANGUAGES({ state, commit, rootGetters }) {
      const response = await localesService.getLocalesList();
      if (response && response.data) {
        commit('SET_DEFAULT_LANGUAGES', response.data);
      }
    }
  },
};
